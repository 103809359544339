const Clients = [
  {
    review:
      "Lorem ipsum dolor sit amet consectetur.uspendisse non proin. Semper neque turpis quis lorem pellentesque viverra dictumst et sit. Quam aenean id aliquet neque sed. Proin in ac posuere ullamcorper .”",
    info: "Incredible Experience",
    img: "./profile1.jpg",
  },
  {
    review:
      "Lorem ipsum dolor sit amet consectetur.uspendisse non proin. Semper neque turpis quis lorem pellentesque viverra dictumst et sit. Quam aenean id aliquet neque sed. Proin in ac posuere ullamcorper .”",
    info: "Dependable, Responsive, Professional Partner",
    img: "./profile2.jpg",
  },
  {
    review:
      "Lorem ipsum dolor sit amet consectetur.uspendisse non proin. Semper neque turpis quis lorem pellentesque viverra dictumst et sit. Quam aenean id aliquet neque sed. Proin in ac posuere ullamcorper .”",
    info: "Dependable, Responsive, Professional Partner",

    img: "./profile3.jpg",
  },
  {
    review:
      "Lorem ipsum dolor sit amet consectetur.uspendisse non proin. Semper neque turpis quis lorem pellentesque viverra dictumst et sit. Quam aenean id aliquet neque sed. Proin in ac posuere ullamcorper .”",

    info: "Anonymous",
    img: "./profile4.jpg",
  },
];
export default Clients;
