import React from "react";

const FAQ = () => {
  return (
    <div className="faq_container left_hero container-fluid py-5" id="faq">
      <h2 className="text-center">Frequently Asked Questions</h2>
      <div className="row container mx-auto mt-5">
        <div className="col-md-6  ">
          <div
            className="accordion accordion-flush "
            id="accordionFlushExample1"
          >
            <div className="accordion-item ">
              <h2 className="accordion-header " id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What services does The Vista Builder offer?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  The Vista Builder provides various services, including
                  construction, furniture, fabrication, plumbing, and electrical
                  work.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  How can I contact The Vista Builder for inquiries or to
                  request services?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  You can reach out to us through our website's "Contact Us"
                  page, where you'll find our phone number and email address.
                  Alternatively, you can use the online inquiry form.
                </div>
              </div>
            </div>{" "}
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingEight">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseEight"
                  aria-expanded="false"
                  aria-controls="flush-collapseEight"
                >
                  What types of construction projects does The Vista Builder
                  undertake?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseEight"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingEight"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  We specialize in various construction projects, such as
                  residential, commercial, and industrial construction. Whether
                  it's a new build, renovation, or remodeling, we have the
                  expertise to handle diverse projects.
                </div>
              </div>
            </div>{" "}
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingNine">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseNine"
                  aria-expanded="false"
                  aria-controls="flush-collapseNine"
                >
                  Do you provide custom furniture design and construction
                  services?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseNine"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingNine"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  Yes, The Vista Builder offers custom furniture design and
                  construction services. We work closely with clients to create
                  unique and tailored pieces to suit their preferences and
                  needs.
                </div>
              </div>
            </div>{" "}
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingTen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTen"
                  aria-expanded="false"
                  aria-controls="flush-collapseTen"
                >
                  What materials does The Vista Builder use in fabrication
                  projects?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseTen"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTen"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  Our fabrication services utilize high-quality materials, and
                  we adapt based on project requirements. Common materials
                  include steel, aluminum, wood, and other metals.
                </div>
              </div>
            </div>{" "}
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingEleven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseEleven"
                  aria-expanded="false"
                  aria-controls="flush-collapseEleven"
                >
                  What areas does The Vista Builder serve?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseEleven"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingEleven"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  Yes, we are a licensed and insured construction and service
                  provider. Our team consists of skilled professionals who
                  adhere to industry standards and safety regulations.
                </div>
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="col-md-6">
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample2"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Is The Vista Builder licensed and insured?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  Absolutely! We have experience in both residential and
                  commercial plumbing. Our plumbing services are comprehensive,
                  whether it's installations, repairs, or maintenance.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  What electrical services does The Vista Builder offer?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  Our electrical services cover a wide range of needs, including
                  wiring, installations, repairs, and upgrades for both
                  residential and commercial properties.{" "}
                </div>
              </div>
            </div>
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  How do I get a quote for services from The Vista Builder?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  To get a quote, you can fill out the online quote request form
                  on our website, providing details about your project.
                  Alternatively, you can contact us directly by phone or email.
                </div>
              </div>
            </div>
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSix"
                  aria-expanded="false"
                  aria-controls="flush-collapseSix"
                >
                  What sets The Vista Builder apart from other construction and
                  service providers?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingSix"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  While our primary location is [mention primary location], we
                  serve a broad area, including [list nearby cities or regions].
                  Feel free to contact us to inquire about services in your
                  specific location.
                </div>
              </div>
            </div>{" "}
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="flush-headingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSeven"
                  aria-expanded="false"
                  aria-controls="flush-collapseSeven"
                >
                  Can The Vista Builder handle both residential and commercial
                  plumbing projects?{" "}
                </button>
              </h2>
              <div
                id="flush-collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingSeven"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  The Vista Builder distinguishes itself through a commitment to
                  quality work, customer satisfaction, and attention to detail.
                  Our experienced team ensures that every project, regardless of
                  size, is handled with precision and professionalism.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
