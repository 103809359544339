import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

const Navbar = () => {
  const [boxShadow, setBoxShadow] = useState("");
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setBoxShadow("0px 0px 20px 1px rgba(158, 160, 162, 0.42)");
      } else {
        setBoxShadow("");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <nav
        style={{ boxShadow: boxShadow }}
        className="normal_nav position-sticky top-0  px-2 py-3  "
      >
        <div className="container d-flex justify-content-between align-items-center">
          <a className="navbar-brand" href="#home">
            <img src={logo} className="logo" alt="logo" />
          </a>

          <ul className="menu  mb-2 me-4 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="#home">
                Home{" "}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Services
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#about">
                About Us
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#portfolio">
                Portfolio
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#blogs">
                Blogs
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#faq">
                FAQs
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#testimonial">
                Testimonials
              </a>
            </li>
          </ul>
          <a href="#contact">
            <button className="btn btn-primary rounded-pill py-2 px-4 ms-4 nav_btn hide_btn">
              Contact Us
            </button>
          </a>
          <button
            className="menu-toggle navMenu btn p-0 shadow-none border-0"
            onClick={toggleMenu}
          >
            {menuOpen ? (
              <AiOutlineMenu size={30} />
            ) : (
              <AiOutlineMenu size={30} />
            )}
          </button>
        </div>
      </nav>

      {/* ............responsive ...............*/}
      <nav
        className={`responsive_nav  position-absolute toggleNavbar px-4 py-3   ${
          menuOpen ? "open" : ""
        }`}
      >
        <div className=" d-flex flex-column justify-content-between ">
          <a
            className="navbar-brand d-flex justify-content-between align-items-center me-0 pe-0 fs-4 fw-bold"
            href="##"
            style={{ color: "#0071bd" }}
          >
            {/* <img src={logo} className="logo" alt="" /> */}
            Menu
            <button
              className="close-menu btn p-0     shadow-none border-0"
              onClick={toggleMenu}
            >
              <RxCross2 size={40} className="p-0 " />
            </button>
          </a>
          <ul className="d-flex flex-column justify-content-between gap-3 mt-4 p-0">
            <li className="nav-item">
              <a className="nav-link" href="#home" onClick={toggleMenu}>
                Home{" "}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services" onClick={toggleMenu}>
                Services
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#about" onClick={toggleMenu}>
                About Us
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#portfolio" onClick={toggleMenu}>
                Portfolio
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#blogs" onClick={toggleMenu}>
                Blogs
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#faq" onClick={toggleMenu}>
                FAQs
              </a>
            </li>{" "}
            <li className="nav-item">
              <a className="nav-link" href="#testimonial" onClick={toggleMenu}>
                Testimonials
              </a>
            </li>
          </ul>
          <p style={{ marginTop: "4rem", color: "#000000", fontWeight: "900" }}>
            {" "}
            GST: 27BUQPK0388E1ZC
          </p>
          <p
            className="fw-bold mb-0 "
            style={{
              color: "#525252",
              letterSpacing: "1px",
            }}
          >
            IND:
          </p>
          <p className="mb-1" style={{ color: "#000000", fontWeight: "900" }}>
            +91 90046 78046{" "}
          </p>

          <a href="#contact" onClick={toggleMenu}>
            <button className="btn btn-primary py-2  nav_btn fs-5 mt-2">
              Contact Us
            </button>
          </a>
        </div>
      </nav>
      <div className={` ${menuOpen ? "overlay2" : ""}`}></div>
    </>
  );
};

export default Navbar;
