import React, { useEffect, useState } from "react";

import { useFirebase } from "../context/firebase";
import PortCard from "./PortCard";
// import furn1 from "../assets/Rectangle 4 (1).png";
import furn1 from "../assets/Project 4.png";
import furn2 from "../assets/Project 5.png";
import furn3 from "../assets/fur1.jpg";
import furn4 from "../assets/fur2.jpg";
import fab1 from "../assets/Project 6.png";
import fab2 from "../assets/fabr1.jpg";
import fab3 from "../assets/fabr2.jpg";
import plumb1 from "../assets/Rectangle 5.png";
import plumb2 from "../assets/plumb1.jpg";
import plumb3 from "../assets/plumb2.jpg";
import plumb4 from "../assets/plumb3.jpg";
import elect1 from "../assets/elect3.webp";
import elect2 from "../assets/Project 3.png";
import elect3 from "../assets/ele2.jpg";
import elect4 from "../assets/ele3.jpg";
import elect5 from "../assets/ele1.webp";

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState([]);
  const firebase = useFirebase();
  useEffect(() => {
    firebase.getPortfolio().then((documents) => {
      setPortfolio(documents.docs);
    });
  }, [firebase]);

  const [activeButton, setActiveButton] = useState("construction");

  const handleButtonClick = (category) => {
    setActiveButton(category);
  };

  const isConstructionActive = activeButton === "construction";
  return (
    <div
      className="container about_text left_hero d-flex flex-column justify-content-center align-items-center py-5"
      id="portfolio"
    >
      <h2 className="text-center port_head">Portfolio</h2>
      <div className="portfolio-buttons-container">
        <button
          className={`btn portfolio_btn ${
            isConstructionActive ? "active_portfolio" : ""
          }`}
          onClick={() => handleButtonClick("construction")}
        >
          Construction
        </button>

        <button
          className={`btn btn-light portfolio_btn ${
            activeButton === "furniture" ? "active_portfolio" : ""
          }`}
          onClick={() => handleButtonClick("furniture")}
        >
          Furniture
        </button>
        <button
          className={`btn btn-light portfolio_btn ${
            activeButton === "fabrication" ? "active_portfolio" : ""
          }`}
          onClick={() => handleButtonClick("fabrication")}
        >
          Fabrication
        </button>
        <button
          className={`btn btn-light portfolio_btn ${
            activeButton === "plumbing" ? "active_portfolio" : ""
          }`}
          onClick={() => handleButtonClick("plumbing")}
        >
          Plumbing
        </button>
        <button
          className={`btn btn-light portfolio_btn ${
            activeButton === "electrical" ? "active_portfolio" : ""
          }`}
          onClick={() => handleButtonClick("electrical")}
        >
          Electrical
        </button>
      </div>
      <div className="construction_images mt-4">
        <div className="image-container">
          {activeButton === "construction" &&
            portfolio?.map((curData, index) => (
              <PortCard key={index} {...curData.data()} />
            ))}
          {activeButton === "furniture" && (
            <div className="image-container">
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={furn1}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={furn3}
                alt=""
                className="portfolio_img"
              />
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={furn2}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={furn4}
                alt=""
                className="portfolio_img"
              />{" "}
            </div>
          )}
          {activeButton === "fabrication" && (
            <div className="image-container">
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={fab1}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={fab2}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={fab3}
                alt=""
                className="portfolio_img"
              />{" "}
            </div>
          )}
          {activeButton === "plumbing" && (
            <div className="image-container">
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={plumb1}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={plumb2}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={plumb3}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={plumb4}
                alt=""
                className="portfolio_img"
              />{" "}
            </div>
          )}{" "}
          {activeButton === "electrical" && (
            <div className=" image-container">
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={elect1}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={elect2}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={elect3}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={elect4}
                alt=""
                className="portfolio_img"
              />{" "}
              <img
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={elect5}
                alt=""
                className="portfolio_img"
              />{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
