import React, { useEffect, useState } from "react";
import { useFirebase } from "../context/firebase";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import UpdateModel from "./models/UpdateModel";

const AdminBlog = (props) => {
  const [updateModel, setUpdateModal] = useState(false);

  const firebase = useFirebase();

  const [url, setUrl] = useState(null);
  useEffect(() => {
    firebase.getImageURL(props.imageURL).then((url) => setUrl(url));
  }, [firebase, props.imageURL]);
  const deletBlog = async () => {
    const res = await firebase.deleteBlogById(props.id);
    if (res) {
      alert("Blog Deleted Successfully");
      props.onDeleteBlog(props.id);
    }
  };
  return (
    <>
      {updateModel && (
        <UpdateModel
          props={props}
          show={updateModel}
          onHide={() => setUpdateModal(false)}
        />
      )}
      <tr className="" key={props.index}>
        <td>{props.index + 1}</td>
        <img
          style={{ width: "2rem", height: "2rem", objectFit: "cover" }}
          src={url}
          alt=""
          className="mt-2"
        />
        <td> {props.title}</td>
        <td> {props.desc}</td>
        <div className="d-flex gap-1">
          <button
            onClick={deletBlog}
            className="btn btn-danger py-1 px-2 bg-danger "
          >
            <MdDelete color="#fff" />
          </button>{" "}
          <button
            onClick={() => setUpdateModal(true)}
            className="btn btn-primary py-1 px-2 bg-primary "
          >
            <AiFillEdit color="#fff" />
          </button>
        </div>
      </tr>
    </>
  );
};

export default AdminBlog;
