import React, { useEffect, useState } from "react";
import { useFirebase } from "../context/firebase";

const PortCard = (props) => {
  const firebase = useFirebase();

  const [url, setUrl] = useState(null);
  useEffect(() => {
    firebase.getImageURL(props.imageURL).then((url) => setUrl(url));
  }, [firebase, props.imageURL]);

  return (
    <div>
      <img
        style={{
          width: "12rem",
          height: "12rem",
          borderRadius: "8px",
          objectFit: "cover",
        }}
        src={url}
        alt=""
        className="portfolio_img"
      />
    </div>
  );
};

export default PortCard;
