import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useFirebase } from "../../context/firebase";

const BlogModel = ({ show, onHide, content }) => {

  const firebase = useFirebase();
  // blog
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const [img, setImg] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImg(file);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await firebase.addBlog(title, desc, img);
    if (res) {
      onHide();
      alert("Blog Added Successfully");
    }
  };
  // porfolio
  const [porfolioImg, setPortfolioImg] = useState("");

  const portfolioFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPortfolioImg(file);
    }
  };
  const handlePorfolioSubmit = async (e) => {
    e.preventDefault();
    const res = await firebase.addPortfolio(porfolioImg);
    if (res) {
      onHide();

      alert("Portfolio Added Successfully");
    }
  };

  return (
    <Modal show={show} onHide={onHide} className="settings-modal mt-5">
      <div className="rounded ">
        <div className="col-12 p-0">
          {content === "Blogs" && (
            <div className="blog_form container-fluid d-flex align-items-center py-3">
              <div className="container d-flex align-items-center justify-content-center inner_blog">
                <div className="c_right">
                  <form onSubmit={handleSubmit}>
                    <h2 className="fw-bold">Add Blogs</h2>{" "}
                    <input
                      type="text"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Title"
                      className="user"
                    />
                    <textarea
                      className="user"
                      name="desc"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      placeholder="Description"
                    ></textarea>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="user"
                    />
                    <div className="mt-2 d-flex justify-content-end  w-100">
                      <input type="submit" className="btn btn-primary  py-2" />
                      <button
                        className="btn btn-danger ms-2 py-2 "
                        onClick={onHide}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {/* blog model  */}

          {/* portfolio model  */}
          {content === "Portfolio" && (
            <div className="blog_form container-fluid d-flex align-items-center py-3">
              <div className="container d-flex align-items-center justify-content-center inner_blog">
                <div className="c_right">
                  <form onSubmit={handlePorfolioSubmit}>
                    <h2 className="fw-bold">Add Portfolio</h2>{" "}
                    <input
                      type="file"
                      onChange={portfolioFileChange}
                      className="user"
                    />
                    <div className="mt-2 d-flex justify-content-end  w-100">
                      <input
                        type="submit"
                        className="btn btn-primary  py-2  "
                      />{" "}
                      <button
                        className="btn btn-danger ms-2 py-2 "
                        onClick={onHide}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BlogModel;
