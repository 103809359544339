import React from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {/* <link
        rel="icon"
        type="image/png"
        href="%PUBLIC_URL%/assets/vista.png"
        sizes="32x32"
      /> */}
    </Helmet>
  );
};

export default MetaData;
