import React, { useEffect, useState } from "react";
import { useFirebase } from "../../context/firebase";
import { Modal } from "react-bootstrap";

const UpdateModel = ({ show, onHide, props }) => {
  const firebase = useFirebase();

  const [title, setTitle] = useState(props.title);
  const [desc, setDesc] = useState(props.desc);
  const [img, setImg] = useState(null);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    firebase.getImageURL(props.imageURL).then((url) => setUrl(url));
  }, [props.imageURL, firebase]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImg(file);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await firebase.updateBlog(props.id, title, desc, img);
    if (res) {
      console.log("updated");
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide} className="settings-modal mt-5">
      <div className="rounded ">
        <div className="col-12 p-0">
          <div className="blog_form container-fluid d-flex align-items-center py-3">
            <div className="container d-flex align-items-center justify-content-center inner_blog">
              <div className="c_right">
                <form onSubmit={handleSubmit}>
                  <h2 className="fw-bold">Update Blogs</h2>{" "}
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Title"
                    className="user"
                  />
                  <textarea
                    className="user"
                    name="desc"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    placeholder="Description"
                  ></textarea>
                  <img
                    style={{
                      width: "5rem",
                      height: "5rem",
                      objectFit: "cover",
                    }}
                    src={url}
                    alt=""
                    className="mt-2"
                  />
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="user"
                  />
                  <div className="mt-2 d-flex justify-content-end  w-100">
                    <input
                      value="Update"
                      type="submit"
                      className="btn btn-primary  py-2"
                    />
                    <button
                      className="btn btn-danger ms-2 py-2 "
                      onClick={onHide}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModel;
