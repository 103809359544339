import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/firebase";
// import blog1 from "../assets/Group 40.png";

const BlogCard = (props) => {
  const firebase = useFirebase();

  const [url, setUrl] = useState(null);
  useEffect(() => {
    firebase.getImageURL(props.imageURL).then((url) => setUrl(url));
  }, [firebase,props.imageURL]);

  return (
    <div
      class="card blog_card"
      style={{ width: "20rem", border: "none", borderRadius: "none" }}
    >
      <img
        src={url}
        class="card-img-top"
        alt="..."
        style={{ width: "20rem", height: "10rem", objectFit: "cover" }}
      />
      <div class="card-body" style={{ backgroundAttachment: "#fbfbfb" }}>
        <button
          className="btn  rounded-pill"
          style={{ backgroundColor: "#F4C467",fontSize:"12px" }}
        >
          NEWS
        </button>
        <h5 class="card-title my-1   fw-bold" style={{ fontSize: "14px" }}>
          {props.title}
        </h5>
        <p class="card-text" style={{ fontSize: "10px" }}>
          {props.desc}
        </p>
        <p
          style={{
            // color: "#696984",
            fontSize: "12px",
            fontWeight: "600",
            cursor: "pointer",
          }}
        >
          Read More
        </p>
      </div>
    </div>
  );
};

export default BlogCard;
