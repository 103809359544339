import React, { useEffect, useState } from "react";
import BlogModel from "../components/models/BlogModel";
import AdminBlog from "../components/AdminBlog";
import { useFirebase } from "../context/firebase";
import { MdDelete } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineMenu } from "react-icons/ai";
import AdminPortfolio from "../components/AdminPortfolio";

const Dashboard = () => {
  const [content, setContent] = useState("Blogs");
  const [blogModel, setBlogModal] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const firebase = useFirebase();
  useEffect(() => {
    firebase.getAllBlogs().then((documents) => {
      setBlogs(documents.docs);
    });
  }, [firebase]);
  const removeBlog = (deletedBlogId) => {
    setBlogs((prevBlogs) =>
      prevBlogs.filter((blog) => blog.id !== deletedBlogId)
    );
  };
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    firebase.getAllContacts().then((documents) => {
      setContacts(documents.docs);
    });
  }, [firebase]);
  const deleteUser = async (id) => {
    console.log(id);
    const res = await firebase.deleteUserById(id);
    if (res) {
      alert("User Deleted Successfully");
    }
  };
  const [portfolio, setPortfolio] = useState([]);
  useEffect(() => {
    firebase.getPortfolio().then((documents) => {
      setPortfolio(documents.docs);
    });
  }, [firebase]);
  const toggleSidebar = (e) => {
    setSidebar(!sidebar);
  };
  const handleItemClick = (content) => {
    setContent(content);
    setSidebar(false);
  };

  return (
    <div>
      {" "}
      <div
        className="w-100 p-2 py-3"
        style={{
          backgroundColor: "#0071bd",
        }}
      >
        {sidebar ? (
          <RxCross2
            size={30}
            onClick={toggleSidebar}
            className="text-white"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <AiOutlineMenu
            size={24}
            onClick={toggleSidebar}
            className="text-white"
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      <div className="admin_dashboard d-flex">
        {sidebar && (
          <div className="sidebar  mt-0 pt-2">
            <ul>
              <li onClick={() => handleItemClick("Dashboard")}>Dashboard</li>
              <li onClick={() => handleItemClick("Blogs")}>Blogs</li>
              <li onClick={() => handleItemClick("Portfolio")}>Portfolio</li>
              <li onClick={() => handleItemClick("Users")}>Users</li>
            </ul>
          </div>
        )}

        <div className="content  w-100">
          <h2 className="text-center">{content}</h2>
          {content === "Blogs" && (
            <>
              <div className="dashboard_btn">
                <button
                  className="btn btn-primary"
                  onClick={() => setBlogModal(true)}
                >
                  Add {content}
                </button>
              </div>
              <div class="container content-text">
                <div class="row">
                  <div class="col-md-12  pt-4">
                    <div className="table-responsive  w-100">
                      <table className="table table-page w-100">
                        <tr>
                          <th className="pe-1">#</th>
                          <th className="pe-1">Image</th>
                          <th className="pe-1">Title</th>

                          <th>Desc</th>
                          <th>Actions</th>
                        </tr>
                        {blogs?.map((blog, index) => {
                          return (
                            <AdminBlog
                              {...blog.data()}
                              id={blog.id}
                              index={index}
                              onDeleteBlog={removeBlog}
                            />
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {content === "Portfolio" && (
            <>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-danger "
                  onClick={() => setBlogModal(true)}
                >
                  Add {content}
                </button>
              </div>
              <div class="container content-text">
                <div class="row">
                  <div class="col-md-12  pt-4">
                    <div className="d-flex flex-wrap justify-content-center align-items-center gap-4  w-100">
                      {portfolio?.map((curData, index) => {
                        return (
                          <AdminPortfolio id={curData.id} {...curData.data()} />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {content === "Users" && (
            <>
              <div class="container content-text">
                <div class="row">
                  <div class="col-md-12  pt-4">
                    <div className="table-responsive  w-100">
                      <table className="table table-page w-100">
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>

                          <th>Message</th>
                          <th>Actions</th>
                        </tr>{" "}
                        {contacts.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {" "}
                                {
                                  user._document.data.value.mapValue.fields
                                    .contact.mapValue.fields.name.stringValue
                                }
                              </td>
                              <td>
                                {" "}
                                {
                                  user._document.data.value.mapValue.fields
                                    .contact.mapValue.fields.email.stringValue
                                }
                              </td>

                              <td>
                                {" "}
                                {
                                  user._document.data.value.mapValue.fields
                                    .contact.mapValue.fields.message.stringValue
                                }
                              </td>
                              <td>
                                <button
                                  onClick={() => deleteUser(user.id)}
                                  className="btn btn-danger py-1 px-2 bg-danger mt-1"
                                >
                                  <MdDelete color="#fff" />
                                </button>{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </>
          )}

          {blogModel && (
            <BlogModel
              show={blogModel}
              onHide={() => setBlogModal(false)}
              content={content}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
