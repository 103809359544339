import React, { useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { useFirebase } from "../context/firebase";

const Contact = () => {
  const firebase = useFirebase();
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await firebase.addContact(contact);
    if (res) {
      alert("Message has sent successfully...");
    }
  };
  return (
    <div
      id="contact"
      className="contact_form container-fluid d-flex align-items-center py-5"
    >
      <div className="container d-flex align-items-center justify-content-between inner_contact">
        <div className="c_left left_hero col-md-5 col-10">
          <h2>Get in Touch with Crafting Your Service Solutions</h2>
          <p>
            Reach out to us and let's start a conversation. We're here to answer
            your questions, provide guidance, and offer our exceptional service
            solutions. Your needs, our priority
          </p>{" "}
          <div className="social_icons d-flex gap-4">
            <span>
              <a
                href="https://www.instagram.com/thevistabuilders"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={20} />
              </a>
            </span>
            <span>
              <a
                href="https://www.facebook.com/TheVistabuilders"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF size={20} />
              </a>
            </span>
            <span>
              <a
                href="https://www.linkedin.com/company/thevistabuilders"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn size={20} />
              </a>
            </span>
          </div>
        </div>
        <div className="c_right">
          <form>
            <input
              type="text"
              name="name"
              value={contact.name}
              onChange={handleChange}
              placeholder="Name"
              className="user"
            />
            <input
              type="email"
              name="email"
              value={contact.email}
              onChange={handleChange}
              placeholder="Email"
              className="user"
            />
            <textarea
              className="user"
              name="message"
              value={contact.message}
              onChange={handleChange}
              placeholder="Message"
            ></textarea>
            <input
              type="submit"
              value="Send"
              name="message"
              className="btn btn-primary  py-2 px-4 mt-2 nav_btn contact_btn"
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
