import React from "react";
import img1 from "../assets/Rectangle 3.png";
import img2 from "../assets/Rectangle 4 (1).png";
import img3 from "../assets/Rectangle 5.png";

const About = () => {
  return (
    <div
      className="container-fluid d-flex align-items-center  py-5  about_container"
      id="about"
    >
      <div className="container d-flex align-items-center justify-content-evenly inner_about">
        <div className="about_images ">
          <div className="d-flex flex-column align-items-end gap-3 ">
            <img src={img2} alt="" />
            <img src={img3} alt="" />
          </div>
          <img src={img1} alt="" />
        </div>
        <div className="col-md-4  left_hero about_text">
          <h4 style={{ fontSize: "14px" }}>About THE VISTA BUILDERS</h4>
          <h2 className="mt-2">Who Are We</h2>
          <p>
            <p>
              {" "}
              we are a team of dedicated experts who take immense pride in the
              work we do. Our journey began with a simple idea: to transform
              ordinary services into extraordinary experiences.
            </p>
            <p>
              {" "}
              Our skilled professionals, whether plumbers, electricians, or any
              other service providers, are not just passionate about their work;
              they are driven by a commitment to excellence. We believe in the
              power of skill and the magic of passion, which is why we have come
              together to create a one-stop destination for all your service
              needs.
            </p>
            With years of experience under our belts, we've fine-tuned our craft
            and honed our skills to meet the highest standards of quality. But
            we don't stop there. What truly sets us apart is our unwavering
            dedication to turning each project into a work of art.
          </p>
          <button className="btn btn-primary rounded-pill py-2 px-4 mt-2 nav_btn">
            Get Started Now!
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
