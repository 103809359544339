import React from "react";
import hero from "../assets/hero.png";
import down from "../assets/down.png";

const HeroSection = () => {
  return (
    <div
      className="container-fluid d-flex align-items-center  py-5 hero_section "
      id="home"
    >
      <div className="container d-flex align-items-center justify-content-between hero">
        <div className="col-md-5 left_hero about_text">
          <h4>THE VISTA BUILDERS</h4>
          <h2>Crafting Your Service Solutions Where Skill Meets Passion</h2>
          <p>
            Discover a world of expertise and dedication at Crafting Your
            Service Solutions. We're a team of skilled professionals ready to
            tackle any project, from plumbing to carpentry and beyond. Our
            passion for providing top-notch services sets us apart.
          </p>
          <a
            href="#portfolio"
            className="btn btn-primary rounded-pill py-2 px-4 mt-2 nav_btn"
          >
            {/* <a> */}
            VIEW PORTFOLIO
            {/* </a> */}
          </a>
          <br />
          <img className="mt-5" src={down} alt="" />
        </div>
        <img className="w-50 moving_image" src={hero} alt="" />
      </div>
    </div>
  );
};

export default HeroSection;
