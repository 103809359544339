import React, { useEffect, useState } from "react";
import { useFirebase } from "../context/firebase";
import { MdDelete } from "react-icons/md";

const AdminPortfolio = (props) => {
  const firebase = useFirebase();

  const [url, setUrl] = useState(null);
  useEffect(() => {
    firebase.getImageURL(props.imageURL).then((url) => setUrl(url));
  }, [firebase,props.imageURL]);
  const deletPortfolio = async (id) => {
    console.log(id);
    if (id) {
      await firebase.deletePortfolioById(id);
    }
  };

  return (
    <div className="admin_portfolio">
      <img src={url} alt="" />
      <div className="overlay">
        <MdDelete
          onClick={() => deletPortfolio(props.id)}
          size={30}
          color="#fff"
        />
      </div>
    </div>
  );
};

export default AdminPortfolio;
