import React from "react";
import icon1 from "../assets/icon1.png";
import icon2 from "../assets/Electricity1.png";
import icon3 from "../assets/Shovel1.png";
import icon4 from "../assets/Shovel.png";
import icon5 from "../assets/Electricity.png";

const Services = () => {
  return (
    <div className="container-fluid our_services" id="services">
      <div className="container py-5 inner_services">
        <p className="">OUR SERVICES</p>
        <h5>We Are Providing services</h5>
        <div className="d-flex flex-wrap gap-3 mt-3">
          <div className="box col-md-3 rounded bg-white py-3 px-3 d-flex align-items-center gap-3">
            <img src={icon1} alt="" />
            <span>Construction</span>
          </div>
          <div className="box col-md-3 rounded  bg-white py-3 px-3 d-flex align-items-center gap-3">
            <img src={icon2} alt="" />
            <span>Furniture</span>
          </div>
          <div className="box col-md-3 rounded  bg-white py-3 px-3 d-flex align-items-center gap-3">
            <img src={icon3} alt="" />
            <span>Fabrication </span>
          </div>
          <div className="box col-md-3 rounded bg-white py-3 px-3 d-flex align-items-center gap-3">
            <img src={icon4} alt="" />
            <span>Plumbing</span>
          </div>
          <div className="box col-md-3 rounded bg-white py-3 px-3 d-flex align-items-center gap-3">
            <img src={icon5} alt="" />
            <span>Electrical</span>
          </div>
        </div>
      </div>

      {/* ///////////  responsive design ///////// */}
      <div className="resposive_service container py-5">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="true"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="w-100 box rounded  bg-white py-3 px-3 d-flex align-items-center  gap-3">
                <img src={icon1} alt="" />
                <span>Construction</span>
              </div>
            </div>
            <div className="carousel-item ">
              <div className="w-100 box rounded  bg-white py-3 px-3 d-flex  align-items-center  gap-3">
                <img src={icon2} alt="" />
                <span>Furniture</span>
              </div>
            </div>
            <div className="carousel-item">
              <div className="w-100 box w-25 rounded bg-white py-3 px-3 d-flex align-items-center  gap-3">
                <img src={icon3} alt="" />
                <span>Fabrication </span>
              </div>
            </div>
            <div className="carousel-item">
              <div className="w-100 box rounded bg-white py-3 px-3 d-flex align-items-center gap-3">
                <img src={icon4} alt="" />
                <span>Plumbing</span>
              </div>
            </div>
            <div className="carousel-item">
              <div className="w-100 box rounded bg-white py-3 px-3 d-flex align-items-center gap-3">
                <img src={icon5} alt="" />
                <span>Electrical</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
