import React, { useState } from "react";
import logo from "../assets/Main Logo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const [serviceShow, setServiceShow] = useState(false);
  const [linkShow, setLinkShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const toggleServices = () => {
    setServiceShow(!serviceShow);
  };
  const toggleLinks = () => {
    setLinkShow(!linkShow);
  };
  const toggleContact = () => {
    setContactShow(!contactShow);
  };
  return (
    <div>
      <div className="footer_container container-fluid pt-5 normal_footer">
        <div className=" inner_footer">
          <div className="foot_logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div>
            <h4>Navigation</h4>
            <div className="d-flex gap-4">
              <div>
                <p className="mb-1">
                  <a href="#home">Home</a>
                </p>
                <p className="mb-1">
                  {" "}
                  <a href="#services">Services</a>
                </p>
                <p className="mb-1">
                  <a href="#about">About</a>
                </p>
                <p className="mb-1 ">
                  <a href="#portfolio">Portfolio</a>
                </p>
                <p className="mb-1 ">
                  <a href="#blogs">Blogs</a>
                </p>{" "}
                <p>
                  <a href="#testimonials">Testimonials</a>
                </p>
              </div>
            </div>
          </div>{" "}
          <div>
            <h4>Services</h4>
            <div className="d-flex gap-4">
              <div>
                <p className="mb-1">Construction</p>
                <p className="mb-1">Plumbing</p>
                <p className="mb-1">Fabrication</p>
                <p className="mb-1 ">Electricals</p>
                <p className="  ">Furniture</p>
              </div>
            </div>
          </div>{" "}
          <div>
            <h4>Quick Links</h4>
            <div className="d-flex gap-4">
              <div>
                <p className="mb-1  ">Privacy Policy</p>
                <p className="mb-1">Terms & Conditions</p>
                <p className="mb-1">Help & Support</p>
                <p className="">FAQs</p>
              </div>
            </div>
          </div>
          {/* <div>
            <h4>Privacy Policy</h4>
            <h4 className="my-3">Terms & Conditions</h4>
            <h4>Help & Support</h4>
          </div>{" "} */}
          <div className="f_icons">
            <h4>Social Links</h4>
            <div className="social_icons d-flex gap-4 mt-4 ">
              <span style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
                <a
                  href="https://www.instagram.com/thevistabuilders"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={20} />
                </a>
              </span>
              <span style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
                <a
                  href="https://www.facebook.com/TheVistabuilders"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF size={20} />
                </a>
              </span>
              <span style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
                <a
                  href="https://www.linkedin.com/company/thevistabuilders"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn size={20} />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center container mt-4 py-2">
          <p className="text-center">
            ©2023 - All Rights Reserved By
            <span className="fw-bold"> THE VISTA BUILDERS</span>
            <br />
            <span className="fw-bold" style={{ letterSpacing: "1px" }}>
              {" "}
              GST: 27BUQPK0388E1ZC
            </span>
            <br />
          </p>
          {/* <span>
            Design and Directed by
            <p className="fw-bold">KHANSTUDIO</p>
          </span> */}
        </div>
      </div>

      {/*///// responsive design ////// */}

      <div className="footer_container container-fluid py-3  responsive_footer">
        <div className="foot_logo d-flex justify-content-center">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="px-2">
          <div style={{ borderBottom: "1px solid #FFFFFF" }}>
            <div
              onClick={toggleServices}
              className="mt-4 mb-1 d-flex align-items-center justify-content-between"
            >
              <h6 className="fw-bold">Services</h6>
              <span style={{ fontSize: "30px" }}>
                {serviceShow ? "-" : "+"}
              </span>
            </div>
            {serviceShow && (
              <div className="mb-2">
                <p className="mb-1">Construction</p>
                <p className="mb-1">Plumbing</p>
                <p className="mb-1">Fabrication</p>
                <p className="mb-1 ">Electricals</p>
                <p className="  ">Furniture</p>
              </div>
            )}
          </div>{" "}
          <div style={{ borderBottom: "1px solid #FFFFFF" }}>
            <div
              onClick={toggleLinks}
              className="my-1 d-flex align-items-center justify-content-between"
            >
              <h6 className="fw-bold">Quick Links</h6>

              <span style={{ fontSize: "30px" }}>{linkShow ? "-" : "+"}</span>
            </div>
            {linkShow && (
              <div className="mb-2 ">
                <p className="mb-1  ">Privacy Policy</p>
                <p className="mb-1">Terms & Conditions</p>
                <p className="mb-1">Help & Support</p>
                <p className="">FAQs</p>
              </div>
            )}
          </div>
          <div style={{ borderBottom: "1px solid #FFFFFF" }}>
            <div
              onClick={toggleContact}
              className="my-1 d-flex align-items-center justify-content-between"
            >
              <h6 className="fw-bold">Contact</h6>

              <span style={{ fontSize: "30px" }}>
                {contactShow ? "-" : "+"}
              </span>
            </div>
            {contactShow && (
              <div className="mb-2 ">
                <p className="mb-1  ">thevistabuilders@gmail.com</p>
                <p className="mb-1">+91 90046 78046</p>
                <p className="mb-1">Thane, Maharashtra 400601</p>
              </div>
            )}
          </div>
          <p className="mb-0 text-center mt-4 " style={{ fontSize: "12px" }}>
            ©2023 - All rights reserved by the vista builders
          </p>
          {/* </div> */}
          <p className="text-center mt-0" style={{ fontSize: "13px" }}>
            {" "}
            GST: 27BUQPK0388E1ZC
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
