import { createContext, useContext } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  doc,
  // getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA36_9s9MO9fXupqUvoiG43Lyngvfh3wT8",
authDomain: "thevistabuilders-85796.firebaseapp.com",
projectId: "thevistabuilders-85796",
storageBucket: "thevistabuilders-85796.appspot.com",
messagingSenderId: "742773206924",
appId: "1:742773206924:web:37827132467fdfa5c330a9",
measurementId: "G-JKREQV9M83",
databaseURL: "https://thevistabuilders-85796-default-rtdb.firebaseio.com",
};

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

const FirebaseContext = createContext(null);
export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = (props) => {
  //  blog adding funtion
  const addBlog = async (title, desc, img) => {
    const imageRef = ref(storage, `uploads/blogsImg/${Date.now()}-${img.name}`);
    const uploadResult = await uploadBytes(imageRef, img);
    return await addDoc(collection(firestore, "blogs"), {
      title,
      desc,
      imageURL: uploadResult.ref.fullPath,
    });
  };
  // getting all blogs
  const getAllBlogs = () => {
    return getDocs(collection(firestore, "blogs"));
  };

  // delete blog by id
  const deleteBlogById = async (id) => {
    const docRef = doc(firestore, "blogs", id);
    const result = await deleteDoc(docRef);
    return result;
  };
  const updateBlog = async (id, title, desc, img) => {
    const imageRef = ref(storage, `uploads/blogsImg/${Date.now()}-${img.name}`);
    const uploadResult = await uploadBytes(imageRef, img);
    const docRef = doc(firestore, "blogs", id);
    await updateDoc(docRef, {
      title: title,
      desc: desc,
      imageURL: uploadResult.ref.fullPath,
    });
  };
  // add portfolio
  const addPortfolio = async (porfolioImg) => {
    const imageRef = ref(
      storage,
      `uploads/portfolioImg/${Date.now()}-${porfolioImg.name}`
    );
    const uploadResult = await uploadBytes(imageRef, porfolioImg);
    return await addDoc(collection(firestore, "portfolio"), {
      imageURL: uploadResult.ref.fullPath,
    });
  };
  const deletePortfolioById = async (id) => {
    const docRef = doc(firestore, "portfolio", id);
    const result = await deleteDoc(docRef);
    return result;
  };

  // download image
  const getImageURL = (path) => {
    return getDownloadURL(ref(storage, path));
  };
  // getting all blogs
  const getPortfolio = () => {
    return getDocs(collection(firestore, "portfolio"));
  };

  //  contact detail adding funtion
  const addContact = async (contact) => {
    return await addDoc(collection(firestore, "contact"), {
      contact,
    });
  };
  const getAllContacts = () => {
    return getDocs(collection(firestore, "contact"));
  };
  const deleteUserById = async (id) => {
    const docRef = doc(firestore, "contact", id);
    const result = await deleteDoc(docRef);
    return result;
  };
  return (
    <FirebaseContext.Provider
      value={{
        addBlog,
        getAllBlogs,
        getImageURL,
        addContact,
        getAllContacts,
        addPortfolio,
        getPortfolio,
        deleteBlogById,
        updateBlog,
        deleteUserById,
        deletePortfolioById,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};

