import React from "react";
import founder from "../assets/founder.png";

import Clients from "./TestimonialData";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

const Testimonial = () => {
  return (
    <>
      <div className="p_container normal_testimonial" id="testimonial">
        {" "}
        <div className="t_wrapper container">
          <div className="t_heading">
            <span>Our Clients Speak </span>
          </div>

          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {Clients.map((curElem, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="testimonial">
                    <div className="d-flex flex-column align-items-center">
                      <img src={curElem.img} alt="" />
                      <span className="fw-bold">Jaane Cooper</span>
                      <span className="mb-2" style={{ fontSize: "12px" }}>
                        CEO, ABC Corporation
                      </span>
                    </div>
                    <p className="text-center mb-0">{curElem.review}</p>
                    <span className="text-center mb-4">{curElem.info}</span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      {/* responsive testimonial  */}
      <div className="p_container responsive_testimoniL " id="testimonial">
        {" "}
        <div className="t_wrapper container">
          <div className="t_heading">
            <span>Our Clients Speak </span>
          </div>

          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {Clients.map((curElem, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="testimonial">
                    <div className="d-flex flex-column align-items-center">
                      <img src={curElem.img} alt="" />
                      <span className="fw-bold">Jaane Cooper</span>
                      <span className="mb-2">CEO, ABC Corporation</span>
                    </div>
                    <p className="text-center mb-0">{curElem.review}</p>
                    <span className="text-center mb-4">{curElem.info}</span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      {/* /////// */}
      <div
        className=" container-fluid d-flex align-items-center  py-5  "
        id="home"
      >
        <div className="container d-flex align-items-center justify-content-between hero">
          <img className="founder" src={founder} alt="" />

          <div className=" left_hero about_text gap-1">
            <p className="fw-bold mb-0">Hello, it’s Me</p>
            <h2 className="py-1 my-0">SAHIM KHAN</h2>
            <h4>founder of the visita builders</h4>
            <p>
              At Crafting Your Service Solutions, we pride ourselves on being a
              team of dedicated professionals who are deeply committed to
              delivering top-notch services. With years of experience and a
              passion for our craft, we excel in transforming routine tasks into
              extraordinary experiences.
            </p>
            <div className="social_icons d-flex gap-4">
              <span>
                <a
                  href="https://www.instagram.com/thevistabuilders"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={20} />
                </a>
              </span>
              <span>
                <a
                  href="https://www.facebook.com/TheVistabuilders"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF size={20} />
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/company/thevistabuilders"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn size={20} />
                </a>
              </span>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
