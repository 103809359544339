import React from "react";
import HeroSection from "../components/HeroSection";
import Services from "../components/Services";
import About from "../components/About";
import Portfolio from "../components/Portfolio";
import Blog from "../components/Blog";
import Testimonial from "../components/Testimonial";
import FAQ from "../components/FAQ";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import MetaData from "../components/MetaData";

const LandingPage = () => {
  return (
    <div>
      <MetaData title="thevistabuilder" />
      <Navbar />
      <HeroSection />
      <Services />
      <About />
      <Portfolio />
      <Testimonial />
      <FAQ />
      <Blog />
      <Contact />
      <Footer />
    </div>
  );
};

export default LandingPage;
