import React, { useEffect, useState } from "react";
// import blog1 from "../assets/Group 40.png";
// import blog2 from "../assets/Rectangle 33.png";
// import blog3 from "../assets/Rectangle 34.png";
import { useFirebase } from "../context/firebase";
import BlogCard from "./BlogCard";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const firebase = useFirebase();
  useEffect(() => {
    firebase.getAllBlogs().then((documents) => {
      setBlogs(documents.docs);
    });
  }, [firebase]);

  return (
    <div
      className="container about_text left_hero d-flex flex-column justify-content-center align-items-center py-5"
      id="blogs"
    >
      <h2 className="mb-1 text-center crafting">
        Crafting Your Service Solutions Blog
      </h2>
      <p className="mt-1 uneveling">
        Unveiling Excellence: Dive into the Skill and Passion of Our Service
        Solutions Blog
      </p>

      <div className="d-flex flex-wrap gap-4 justify-content-center my-5">
        {blogs?.map((blog, index) => {
          return <BlogCard {...blog.data()} index={index} />;
        })}
      </div>
    </div>
  );
};

export default Blog;
